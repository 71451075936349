<template>
  <div class="panel review-card">
    <div class="title-row">
      <PanelTitle :title="title"></PanelTitle>
      <div v-if="isEdit">
        <span @click="isEdit = false">取消</span>
        <span class="theme-text ml8" @click="handleRemove">移除</span>
      </div>
      <span v-if="!isEdit && taskList.length > 0" class="theme-text" @click="isEdit = true">编辑</span>
    </div>
    <div class="list" v-if="taskList.length > 0">
      <div class="task" v-for="(item, index) in taskList" :key="index">
        <div class="flex items-center">
          <LoadImage class="avatar" :src="item.logo" :default="require('@/assets/images/avatar-default.png')"/>
          <div>
            <div class="pname">{{ item.projectName }}</div>
            <div class="ptitle">{{ item.taskTitle }}</div>
          </div>
        </div>
        <div class="flex items-center">
          <div class="mr4">
            <div class="price">
              +{{ item.price }}{{ item.priceUnit }}
            </div>
            <div class="num">{{ item.completeNum }}人已赚</div>
          </div>
          <el-checkbox v-if="isEdit" v-model="item.choose"></el-checkbox>
        </div>
      </div>
    </div>
    <div v-else  class="empty">
      <Empty/>
    </div>
    
    <!-- <div class="list">
      <div class="task" v-for="(item, index) in 5" :key="index">
        <div class="flex items-center">
          <LoadImage class="avatar" :src="require('@/assets/images/avatar-default.png')"/>
          <div>
            <div class="pname limit">下载注册</div>
            <div class="ptitle limit">测试任务666</div>
          </div>
        </div>
        <div class="right">
          <div>
            <div class="price">
              +2.00元
            </div>
            <div class="num">1000人已赚</div>
          </div>
          <el-checkbox v-if="isEdit"></el-checkbox>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import LoadImage from "@/components/LoadImage"
import Empty from "@/components/Empty"
export default {
  components: {
    PanelTitle,
    LoadImage,
    Empty
  },
  data(){
    return {
      isEdit: false
    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    taskList() {
      if(!this.list) return []
      return this.list.map(item => {
        this.$set(item, 'choose', false)
        return item
      })
    }
  },
  methods: {
    handleRemove() {
      const list = []
      this.taskList.forEach(item => {
        if(item.choose) list.push(item.taskId)
      })
      this.isEdit = false
      this.$emit('remove', {list})
    }
  }
  
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 8px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #fff;
	border-radius: 32px;
}
.review-card {
  width: 290px;
  padding: 24px 0;
}
.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  padding: 0 24px 12px;
  font-size: 16px;
  color: #65666F;
  span {
    cursor: pointer;
  }
}
.list {
  max-height: 500px;
  min-height: 280px;
  overflow-y: auto;
  padding: 0 12px 0 24px;
}
.task {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #F0F0F0;
  padding: 8px 10px;
  border-radius: 8px;
  margin-top: 12px;
  .avatar {
    width: 34px;
    height: 34px;
    border-radius: 17px;
    margin-right: 4px;
    overflow: hidden;
  }
  .pname {
    color: #65666f;
    font-size: 12px;
  }
  .ptitle {
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
  }
  .limit {
    max-width: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .price {
    font-size: 14px;
    font-weight: bold;
    color: var(--theme-color-red);
  }
  .num {
    font-size: 12px;
    color: #b0b3bf;
    margin-top: 4px;
  }
  .right {
    display: flex;
    align-items: center;
    text-align: right;
    ::v-deep .el-checkbox {
      margin-left: 8px;
    }
  }
}
.active {
  border: 1px solid var(--theme-color);
}
.empty {
  min-height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>