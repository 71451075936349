<template>
  <div class="panel card">
    <div class="title-row">
      <PanelTitle title="选择要秒审的任务"></PanelTitle>
      <div class="btn-all" @click="openTotalTask">
        <span>全部任务</span>
        <img src="@/assets/images/arrow-right-theme.png">
      </div>
    </div>
    <div class="task-list" v-if="taskList.length > 0">
      <div class="task" v-for="(item, index) in taskList" :key="index" @click="taskClick(item)">
        <img v-if="item.choose" class="img-choose" src="@/assets/images/img-choose.png">
        <div class="flex items-center">
          <LoadImage class="avatar" :src="item.logo" :default="require('@/assets/images/avatar-default.png')"/>
          <div>
            <div class="pname">{{ item.projectName }}</div>
            <div class="ptitle">{{ item.taskTitle }}</div>
          </div>
        </div>
        <div class="flex items-center">
          <div class="mr4">
            <div class="price">
              +{{ item.price }}{{ item.priceUnit }}
            </div>
            <div class="num">{{ item.completeNum }}人已赚</div>
          </div>
        </div>
      </div>
    </div>
    <Empty v-else/>
    <div class="btn-add" @click="handleAdd">添加到{{this.type}}分钟内审核</div>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import LoadImage from "@/components/LoadImage"
import Empty from "@/components/Empty"
export default {
  components: {
    PanelTitle,
    LoadImage,
    Empty
  },
  data() {
    return {
      currentTaskId: ''
    }
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    type: {
      type: [String, Number],
      default: 0
    },
  },
  computed: {
    taskList() {
      if(!this.list) return []
      return this.list.map(item => {
        this.$set(item, 'choose', false)
        return item
      })
    }
  },
  methods: {
    taskClick(info) {
      this.taskList.forEach(item => {
        if(info.taskId == item.taskId) {
          item.choose = true
        } else {
          item.choose = false
        }
      })
      this.currentTaskId = info.taskId
    },
    openTotalTask() {
      this.$emit('totalChoose')
    },
    handleAdd() {
      if(!this.currentTaskId) return this.$tips({message: '请选择一个任务', type: 'warning'})
      this.$emit('add', {taskIds: [this.currentTaskId], type: this.type})
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 8px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #fff;
	border-radius: 32px;
}
.card {
  width: 290px;
  padding: 24px 0;
}
.title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px 12px 24px;
  span {
    cursor: pointer;
  }
}
.btn-all {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--theme-color);
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
    margin-left: 4px;
  }
}
.task-list {
  max-height: 500px;
  min-height: 280px;
  overflow-y: auto;
  padding: 0 12px 0 24px;
}
.task {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #F0F0F0;
  padding: 8px 10px;
  border-radius: 8px;
  margin-top: 12px;
  cursor: pointer;
  .img-choose {
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
  }
  .avatar {
    width: 34px;
    height: 34px;
    border-radius: 17px;
    margin-right: 4px;
    overflow: hidden;
  }
  .pname {
    color: #65666f;
    font-size: 12px;
  }
  .ptitle {
    font-size: 14px;
    font-weight: bold;
    margin-top: 4px;
  }
  .limit {
    max-width: 80px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .price {
    font-size: 14px;
    font-weight: bold;
    color: var(--theme-color-red);
  }
  .num {
    font-size: 12px;
    color: #b0b3bf;
    margin-top: 4px;
  }
  .right {
    display: flex;
    align-items: center;
    text-align: right;
    ::v-deep .el-checkbox {
      margin-left: 8px;
    }
  }
}
.btn-add {
  height: 46px;
  line-height: 46px;
  text-align: center;
  border-radius: 8px;
  color: #fff;
  background-color: #FE3D3D;
  font-size: 14px;
  margin: 8px 24px 0;
  cursor: pointer;
}
</style>