<template>
  <div>
    <ContentLayout>
      <template #left>
        <div class="panel">
          <div class="flex between-center">
            <PanelTitle title="秒审专区" :title-class="['fs20']"></PanelTitle>
            <RulePopover :rule="reviewRule"></RulePopover>
          </div>
          <div class="tips">
            <img src="@/assets/images/icon-ling.png">
            <div>每个任务加入秒审专区需支付2元/天，从任务币账户即时扣费。每日快速审核时段为06:00~24:00，其余时段不开放，24:00后自动下架快速审核资源，23:00后暂停上架快速审核，更多内容详见规则说明</div>
          </div>
        </div>
        <div class="review-row">
          <ReviewListCard v-for="(item, index) in tabList" :key="index" :title="item.text" :list="item.taskList" 
          @remove="handleRemove($event, item.type)"/>
        </div>

        <!-- 选择要审核的任务 -->
        <div class="review-row">
          <AddReviewCard v-for="(item, index) in tabList" :key="index" :type="item.type" :list="item.taskListChoose" 
          @add="handleAdd" @totalChoose="openTotalTask(item.type)"></AddReviewCard>
        </div>
      </template>
      <template #right>
        <PromoteSection></PromoteSection>
        <Asset class="mt16"></Asset>
      </template>
    </ContentLayout>

    <!-- 全部任务弹窗 -->
    <TaskListTotalPopup ref="totalPopupRef" :list="totalTaskList" :title="`添加到${this.totalTaskType}分钟内审核`" confirm-text="确认添加"
    @loadmore="getTotalTask($event.page)" @confirm="handleAdd({type: totalTaskType, taskIds: [$event.detail.taskId]})"></TaskListTotalPopup>
  </div>
</template>

<script>
import PanelTitle from '@/components/PanelTitle'
import PromoteSection from '@/components/PromoteSection'
import Asset from '@/components/Asset'
import ContentLayout from '@/components/ContentLayout'
import RulePopover from '@/components/RulePopover'
import TaskListTotalPopup from '@/components/TaskListTotalPopup'
import ReviewListCard from './components/ReviewListCard.vue'
import AddReviewCard from './components/AddReviewCard.vue'
import { quickAuditManageApi } from '@/api/task.js'
export default {
  name: 'QuickReview',
  components: {
    PanelTitle,
    PromoteSection,
    Asset,
    ContentLayout,
    RulePopover,
    ReviewListCard,
    TaskListTotalPopup,
    AddReviewCard
  },
  data() {
    return {
      tabList: [],
      reviewRule: '',
      totalTaskList: [],
      totalTaskType: 15
    }
  },
  created() {
    this.getReviewManageData()
    
  },
  methods: {
    getReviewManageData() {
      quickAuditManageApi({
        'act': 0,
				'quickAuditType': 0
      }).then(res => {
        console.log('极速审核', res)
        const tablist = res.data.tabs
        tablist.forEach(item => {
          item.taskList = []
          item.taskListChoose = []
          quickAuditManageApi({
            'act': 0,
				    'quickAuditType': item.type
          }).then(res => {
            item.taskList = res.data.tasks || []
          }).catch(err => {
            this.$tips({message: err, type: 'error'})
          })
          // 查询可加入任务
          quickAuditManageApi({
            'act': 3,
            'startPage': 0,
            'quickAuditType': item.type
          }).then(res => {
            item.taskListChoose = res.data.tasks || []
          }).catch(err => {
            this.$tips({message: err, type: 'error'})
          })
        })
        this.tabList = tablist
        this.reviewRule = res.data.quickAuditRule
      }).catch(err => {
         this.$tips({message: err, type: 'error'})
      })
    },
    handleRemove(e, type) {
      if(e.list.length <= 0) return this.$tips({message: '请选择要移除的任务！', type: 'warning'})
      quickAuditManageApi({
        'act': 2,
				'quickAuditType': type,
        'taskIds': e.list
      }).then(res => {
        console.log('移除任务', res)
        this.$tips({message: res.msg, type: 'success'})
        this.updateList(type)
        this.updateChooseList(type)
        this.$store.dispatch('GET_USER_INFO') //更新余额
      }).catch(err => {
         this.$tips({message: err, type: 'error'})
      })
    },
    handleAdd(e) {
      quickAuditManageApi({
        'act': 1,
				'quickAuditType': e.type,
        'taskIds': e.taskIds
      }).then(res => {
        this.$tips({message: res.msg, type: 'success'})
        this.updateChooseList(e.type)
        this.updateList(e.type)
        this.$store.dispatch('GET_USER_INFO') //更新余额
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    updateList(type) {
      quickAuditManageApi({
        'act': 0,
        'quickAuditType': type
      }).then(res => {
        this.tabList.forEach(item => {
          if(item.type == type) {
            item.taskList = res.data.tasks || []
          }
        })
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    updateChooseList(type) {
      quickAuditManageApi({
        'act': 3,
        'startPage': 0,
        'quickAuditType': type
      }).then(res => {
        this.tabList.forEach(item => {
          if(item.type == type) {
            item.taskListChoose = res.data.tasks || []
          }
        })
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    },
    openTotalTask(type) {
      this.totalTaskType = type
      this.$refs.totalPopupRef.open()
      this.getTotalTask()
    },
    getTotalTask(pageNo = 1) {
      quickAuditManageApi({
        'act': 3,
        'startPage': pageNo - 1 < 0 ? 0 : pageNo - 1,
        'quickAuditType': this.totalTaskType
      }).then(res => {
        const list = res.data.tasks || []
        if(pageNo == 1) {
          this.totalTaskList = list
        } else {
          this.totalTaskList = this.totalTaskList.concat(list)
          if(list.length == 0) this.$tips({message: '没有更多数据了', type: 'warning'})
        }
        
      }).catch(err => {
        this.$tips({message: err, type: 'error'})
      })
    }
  }

}
</script>

<style lang="scss" scoped>
.tips {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid var(--theme-color);
  background-color: #FFF2E7;
  color: var(--theme-color);
  margin-top: 16px;
  font-size: 12px;
  img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}
.review-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 12px;
}
</style>